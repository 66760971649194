<script>
export default {
  metaInfo() {
    return {
      title: 'Manuscripts - Developers - Europe PMC',
    }
  },
  data() {
    return {
      idRanges: [1, 11],
    }
  },
  computed: {
    ids() {
      const { idRanges } = this
      return new Array(idRanges[1] - idRanges[0] + 1)
        .fill(0)
        .map((elem, index) => elem + index + idRanges[0])
    },
  },
  methods: {
    createIdBoundary(id) {
      const prefix = id <= 9 ? 'PMC00' : 'PMC0'
      return prefix + id + 'xxxxxx'
    },
    createIdFileName(id, format) {
      return (
        'author_manuscript_' +
        format +
        '.' +
        this.createIdBoundary(id) +
        '.baseline.2024-12-16.tar.gz'
      )
    },
    createIdFileLink(id, format) {
      return (
        '//europepmc.org/ftp/manuscripts/' + this.createIdFileName(id, format)
      )
    },
  },
}
</script>
<template>
  <div id="manuscripts-download-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Author manuscript collection</h1>
        <p>
          The Europe PMC Author Manuscript Collection consists of articles in
          <a href="/Help#authmanuscripts">author manuscript</a> form that have
          been made available in Europe PMC and PubMed Central (PMC) in
          compliance with Europe PMC funder policies and the public access
          policies of NIH and other funders that participate in PMC. The text of
          manuscripts in the Collection may be downloaded in XML and plain text
          formats.
        </p>

        <h2>Copyright</h2>
        <p>
          These files are available for text mining. They may also be used
          consistent with the principles of applicable copyright law.
        </p>

        <h2>Download the author manuscript collection</h2>
        <p>
          The files can be accessed using
          <a href="/ftp/manuscripts/">Europe PMC's FTP service</a>.
        </p>
        <p>
          The files have been packaged based on PMCID. This means that an author
          manuscript XML that has a PMCID of PMC3947720 would be packaged in the
          file
          <a :href="createIdFileLink(3, 'xml')">{{
            createIdFileName(3, 'xml')
          }}</a
          >. As of Dec 2024, all author manuscripts have PMCIDs that fall in the
          range of {{ createIdBoundary(idRanges[0]) }} to
          {{ createIdBoundary(idRanges[1]) }}. Note that these files are quite
          large (up to 4 GB).
        </p>

        <p>The files that contain the XML of all of the articles are:</p>
        <ul>
          <li v-for="id in ids" :key="id">
            <a :href="createIdFileLink(id, 'xml')">{{
              createIdFileName(id, 'xml')
            }}</a>
          </li>
        </ul>

        <p>The plain text files containing the extracted full text are:</p>
        <ul>
          <li v-for="id in ids" :key="id">
            <a :href="createIdFileLink(id, 'txt')">{{
              createIdFileName(id, 'txt')
            }}</a>
          </li>
        </ul>

        <p>
          A set of three files will be available daily for both XML and TXT
          versions of the author manuscripts. Here is an example of the files
          for the TXT versions on 2024-12-17
        </p>
        <ol>
          <li>
            <em>author_manuscript_txt.incr.2024-12-17.filelist.csv</em>
            containing all the manuscripts metadata inserted on 17th of Dec in
            CSV format
          </li>
          <li>
            <em>author_manuscript_txt.incr.2024-12-17.filelist.txt</em>
            containing all the manuscripts metadata inserted on 17th of Dec in
            TXT format
          </li>
          <li>
            <em>author_manuscript_txt.incr.2024-12-17.tar.gz</em>
            containing the content in text format of all the manuscripts
            inserted on 17th of Dec
          </li>
        </ol>
        <p>
          An equivalent set of files are available for the corresponding XML
          versions of author manuscripts.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#manuscripts-download-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
}
</style>
