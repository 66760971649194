import { fetchPrivacyNotice } from '@/api'
import { getCookie } from '@/helpers/cookie'

export const getPrivacyNoticeApiKey = (type) => {
  let key = ''
  if (type === 'advanced-user-services') {
    key = '5d182831-5e99-4072-816b-380cda5f58df'
  } else if (type === 'ux-research') {
    key = '0851ad52-89e5-406e-8f6a-e374feccae1e'
  } else {
    key = 'd667efee-2af8-401f-b7e8-dcc6db7e9a0f'
  }
  return key
}

export const isAcceptedVersionNumberChanged = async () => {
  const key = getPrivacyNoticeApiKey()
  const response = await fetchPrivacyNotice(key)
  const privacyNotice = response ? response.nodes[0].node : null
  const privacyPolicyVersionCount =
    privacyNotice['version Count'] || privacyNotice['version_count']
  const acceptedPrivacyPolicyVersionCount = getCookie(
    'privacyPolicyVersionCount'
  )
  if (acceptedPrivacyPolicyVersionCount)
    if (
      privacyPolicyVersionCount === parseInt(acceptedPrivacyPolicyVersionCount)
    ) {
      return false
    }
  return true
}
